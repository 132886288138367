import React, { useState } from 'react';
import Acnavbar from '../components/Acnavbar';
import Wrapper from '../components/Wrapper';
import Seo from '../shared/seo';
import { Col, Container, Row } from 'react-bootstrap';
import '../assets/scss/index.scss';
import { graphql } from 'gatsby';
import BlogCard from '../components/BlogCard';
import Footer from '../components/footer';
import Hero from '../components/Hero';

function BlogCategory({ pageContext, data }: any) {
  const Articles = data.allContentfulBlogArticle.edges.map(
    (node: any) => node.node
  );
  const filteredArticles = Articles.filter(
    (article: { title: any }) => article.title !== pageContext.title
  );

  return (
    <Wrapper>
      <Seo
        title={pageContext.seoTitle}
        description={pageContext.seoDescription}
      />
      <div>
        <Acnavbar
          home={false}
          pathname={'blogs/tutorial'}
          logo={''}
          logoLogic={false}
        />

        <Hero
          isScrollAnimationPresent={false}
          headerText="Bugs keeping you awake at night?"
          subHeading="Don't let another bug cost your business! Instantly capture visual feedback and seamlessly integrate with your favourite 3rd party tools."
        />

        <div className="py-lg-5 py-4">
          <Container>
            <div className="">
              <div className="fnt-lg-32 fnt-md-32 fnt-sm-20 fw-700 lh-sm-22 mb-2 mb-lg-4 pb-1 pb-lg-0 text-center">
                Simplify Your Bug Tracking
              </div>
            </div>
            <BlogCard data={filteredArticles} />
          </Container>
        </div>
      </div>
      <Footer pathname="blogs" />
    </Wrapper>
  );
}
export default BlogCategory;

export const data = graphql`
  query PagesQuery {
    allContentfulBlogArticle {
      edges {
        node {
          id
          title
          timeToRead
          slug
          createdAt
          author {
            designation
            name
            profileImage {
              file {
                contentType
                fileName
                url
              }
            }
          }
          previewImage {
            id
            file {
              contentType
              fileName
              url
            }
            gatsbyImageData(layout: FIXED)
          }
          Categories {
            id
            title
            contentful_id
            slug
          }
          body {
            id
            title
            image {
              file {
                contentType
                fileName
                url
              }
            }
            description {
              raw
            }
          }
        }
      }
    }
  }
`;
